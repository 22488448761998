export const validEmail = (val) =>
  /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(val);

export const validDomain = (val) => /^[-a-z0-9@:%_\+~#=]{1,256}\.[a-z0-9()]{2,256}\b([-a-z0-9()@:%_\+.~#?&//=]{2,13})$/i.test(val);

export const validateDomain = (value) => {
  // Expresión regular para validar dominio/subdominio
  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  return domainRegex.test(value);
};
