import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
//import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faLock } from '@fortawesome/free-solid-svg-icons';

import { getAuth } from 'libs/cookies';
//import sidebarAdminSections from 'libs/sidebarAdminSections';

import IconCloseDesple from 'components/icons/closeDesple';
import IconHome from 'components/icons/home';
import IconSettings from 'components/icons/settings';
import IconPromo from 'components/icons/promo';
import IconNotice from 'components/icons/notice';
import IconStatsOlecams from 'components/icons/statsOlecams';
import IconSoporte from 'components/icons/sopport';
import IconHerramienta from 'components/icons/herramienta';
import IconLock from 'components/icons/lock';
import IconPadLock from 'components/icons/padlock';

//style
import './index.scss';
//

const Sidebar = ({ showSidebar }) => {
  const auth = getAuth();
  //const { section, part } = useParams();
  const location = useLocation();
  const [admin, setAdmin] = useState(location.pathname.startsWith('/administracion/') ? true : false);
  const [promo, setPromo] = useState(location.pathname.startsWith('/promocion/') ? true : false);
  //const [adminPromotion, setAdminPromotion] = useState(location.pathname.startsWith('/admin/promocion/') ? true : false);
  //const [setting, setSetting] = useState(location.pathname.startsWith('/herramientas-administrativas/') ? true : false);
  const [broker, setBroker] = useState(location.pathname.startsWith('/broker/') ? true : false);

  return (
    <div className="sidebar-content">
      <ul className="menu-ul">
        <li>
          <NavLink to="/estadisticas" role="button">
            <IconHome />
            <FormattedMessage id="sidebar-home" />
          </NavLink>
        </li>
        <li className={classNames({ opendesplegable: admin })}>
          <button onClick={(e) => setAdmin(!admin)} className={classNames('admin', { active: admin })}>
            <IconSettings />
            <span>
              <FormattedMessage id="sidebar-adm" />
            </span>
            <IconCloseDesple color="#A2A9B0" />
          </button>
          <ul className={classNames('submenu-ul', { open: admin })}>
            <li>
              <NavLink to="/administracion/datos" role="button" onClick={showSidebar}>
                <FormattedMessage id="sidebar-adm-data" />
              </NavLink>
            </li>
            {!auth.id_broker && (
              <>
                <li>
                  <NavLink to="/administracion/tarifas" role="button" onClick={showSidebar}>
                    <FormattedMessage id="sidebar-adm-rate" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/administracion/referidos" role="button" onClick={showSidebar}>
                    <FormattedMessage id="sidebar-adm-referred" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/administracion/facturacion" role="button" onClick={showSidebar}>
                    <FormattedMessage id="sidebar-adm-billing" />
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/administracion/domains" role="button" onClick={showSidebar}>
                    <FormattedMessage id="sidebar-domains" />
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </li>
        {auth.id_broker && (
          <li className={classNames({ opendesplegable: broker })}>
            <button onClick={(e) => setBroker(!broker)} className={classNames('broker', { active: broker })}>
              <FontAwesomeIcon icon={faChartLine} className="mr-3" />
              <span>Broker</span>
              <IconCloseDesple color="#A2A9B0" />
            </button>
            <ul className={classNames('submenu-ul', { open: broker })}>
              <li>
                <NavLink to="/broker/perfil" role="button" onClick={showSidebar}>
                  <FormattedMessage id="sidebar-profile" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/broker/app" role="button" onClick={showSidebar}>
                  <FormattedMessage id="sidebar-broker-app" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/broker/estadisticas" role="button" onClick={showSidebar}>
                  <FormattedMessage id="sidebar-stats" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/broker/documentacion" role="button" onClick={showSidebar}>
                  <FormattedMessage id="sidebar-documentation" />
                </NavLink>
              </li>
            </ul>
          </li>
        )}
        {!auth.id_broker && (
          <li className={classNames({ opendesplegable: promo })}>
            <button onClick={(e) => setPromo(!promo)} className={classNames('promo', { active: promo })}>
              <IconPromo />
              <span>
                <FormattedMessage id="sidebar-promo" />
              </span>
              <IconCloseDesple color="#A2A9B0" />
            </button>
            <ul className={classNames('submenu-ul', { open: promo })}>
              <li>
                <NavLink to="/promocion/landing" role="button" onClick={showSidebar}>
                  <FormattedMessage id="sidebar-promo-landing" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/promocion/marca-blanca" role="button" onClick={showSidebar}>
                  <FormattedMessage id="sidebar-promo-white-brand" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/promocion/banners" role="button" onClick={showSidebar}>
                  <FormattedMessage id="sidebar-promo-banner" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/promocion/banners-dinamicos" role="button" onClick={showSidebar}>
                  <FormattedMessage id="sidebar-promo-roomlists" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/promocion/live-room" role="button" onClick={showSidebar}>
                  <FormattedMessage id="sidebar-promo-live-room" />
                </NavLink>
              </li>
            </ul>
          </li>
        )}
        {!auth.id_broker && (
          <li>
            <NavLink to="/estadisticas" role="button">
              <IconStatsOlecams color="#A2A9B0" />
              <FormattedMessage id="sidebar-stats" />
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to="/noticias" role="button">
            <IconNotice color="#A2A9B0" />
            <FormattedMessage id="sidebar-news" />
          </NavLink>
        </li>
        <li>
          <NavLink to="/soporte" role="button">
            <IconSoporte color="#A2A9B0" />
            <FormattedMessage id="sidebar-support" />
          </NavLink>
        </li>
        <li>
          <NavLink to="/api" role="button">
            <FontAwesomeIcon icon={faLock} className="mr-3" />
            API
          </NavLink>
        </li>
        {auth.admin === 1 && (
          <>
            <li>
              <NavLink to="/admintools" role="button" className="admin">
                <IconHerramienta color="#A2A9B0" />
                Admin Tools
              </NavLink>
            </li>
          </>
        )}
        {/* <li>
          <Link to={{ pathname: 'https://oldpanel.promocionesweb.com' }} role="button" target="_blank" rel="noopener noreferrer">
            <IconHerramienta color="#A2A9B0" />
            {translate('sidebar-old-panel')}
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default Sidebar;
