import { useIntl } from 'react-intl';
import { validEmail as validEmailBool, validDomain as validDomainBool } from 'shared/utils';

const useValidation = () => {
  const { formatMessage } = useIntl();

  const includesUppercase = (v) => /[A-Z]/.test(v) || formatMessage({ id: 'validation-includes-uppercase' });

  const includesLowerCase = (v) => /[a-z]/.test(v) || formatMessage({ id: 'validation-includes-lowercase' });

  const includesNumber = (v) => /[\d]/.test(v) || formatMessage({ id: 'validation-includes-number' });

  const minLength = (v, length = 0) => v.length >= length || formatMessage({ id: 'validation-min-length' }, { length });

  const maxLength = (value, length = 8) => value.length <= length || formatMessage({ id: 'validation-max-length' }, { length });

  const validEmail = (v) => validEmailBool(v) || formatMessage({ id: 'validation-valid-email' });

  const validDomain = (v) => validDomainBool(v) || formatMessage({ id: 'validation-valid-domain' });

  const notEmpty = (v) => (v !== null && v !== undefined && v !== '') || formatMessage({ id: 'validation-not-empty' });

  const onlyLettersNumbersAndDashes = (v) =>
    /^[a-zA-Z0-9-_]+$/.test(v) || formatMessage({ id: 'validation-only-letters-numbers-and-dashes' });

  const onlyLettersAndNumbers = (v) =>
    /^[a-zA-Z0-9]+$/.test(v) ||
    formatMessage({
      id: 'validation-only-letters-and-numbers',
    });

  const isDistinct = (v, value) => v !== value || formatMessage({ id: 'validation-is-distinct' });

  const isEqual = (v, value) => v === value || formatMessage({ id: 'validation-is-equal' });

  const isPhone = (v) => /^[\d]{6,}$/.test(v) || formatMessage({ id: 'validation-is-phone' });

  return {
    includesUppercase,
    includesLowerCase,
    includesNumber,
    minLength,
    maxLength,
    validEmail,
    validDomain,
    notEmpty,
    onlyLettersNumbersAndDashes,
    onlyLettersAndNumbers,
    isDistinct,
    isEqual,
    isPhone,
  };
};

export default useValidation;
