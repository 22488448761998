import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Container, Col, Form, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner, faPlus, faTools, faEdit, faEraser, faCheck } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import DomainsService from 'shared/domains-service';

import Button from 'components/button';
import CustomCard from 'components/card';
import Pagination from 'components/pagination';
import IconData from 'components/icons/data';

const AdminDomains = () => {
  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);

  const [filters, setFilters] = useState({
    search: '',
  });
  const [response, setResponse] = useState([]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const sendSubmit = (e) => {
    e.preventDefault();
    getData();
  };

  const getData = () => {
    setResponse([]);
    setPage(1);
    dispatch({ type: 'SET_LOADING', loading: true });
    DomainsService.adminList({ page: 1, perPage, filters })
      .then(({ status, message }) => {
        status === 'Failed' && toast.error('No se pudo cargar la información');
        if (status === 'Accepted') {
          setResponse(message);
          setLastPage(message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  useEffect(() => {
    setResponse([]);
    dispatch({ type: 'SET_LOADING', loading: true });
    DomainsService.adminList({ page, perPage, filters })
      .then(({ status, message }) => {
        status === 'Failed' && toast.error('No se pudo cargar la información');
        if (status === 'Accepted') {
          setResponse(message);
          setLastPage(message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [page, perPage, dispatch]);

  /* PAGINATOR */
  const paginate = (e) => setPage(e.target.value);

  const handleChangeNumberOfColum = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };
  // End Paginator

  return (
    <Container className="mw-100 ha-discounts">
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>Editor de Dominios Autorizados </Card.Header>
            <Card.Body>
              <FormFilters filters={filters} handleOnChange={handleOnChange} sendSubmit={sendSubmit} />
              <br />
              {response && response.data && response.total > 0 ? (
                <>
                  <Row className="mb-4">
                    <Col>
                      <DataTables data={response.data} getData={getData} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Pagination
                        handleChangeNumberOfColum={handleChangeNumberOfColum}
                        pager={{ page: page, items: perPage, pages: lastPage }}
                        paginate={paginate}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        total={response.total}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <>{!loading && <>No hay datos</>}</>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const FormFilters = ({ filters, handleOnChange, sendSubmit }) => {
  const loading = useSelector((state) => state.loading.loading);
  const history = useHistory();
  return (
    <Row className="justify-content-between">
      <Col>
        <form className={`search-wrapper`} onSubmit={sendSubmit}>
          <div className="first-filters d-flex align-center">
            <Form.Group className="mr-3" controlId="formSearch">
              <Form.Label>Buscar por Id, dominio ...</Form.Label>
              <Form.Control value={filters.search} name="search" onChange={handleOnChange} />
            </Form.Group>
          </div>
          <div className="buttons-content">
            <Button rounded disabled={loading} type="submit">
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
                </>
              )}
            </Button>
          </div>
        </form>
      </Col>

      <Col className="text-right align-self-center">
        <Button blue rounded className="mb-2" onClick={() => history.push('/admintools/domains/nuevo')}>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Crear nuevo dominio
        </Button>
        <br />
      </Col>
    </Row>
  );
};

const DataTables = ({ data, getData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const edit = (e, id) => {
    e.preventDefault();
    history.push(`/admintools/domains/${id}`);
  };

  const remove = (id) => {
    if (window.confirm(formatMessage({ id: 'herramientas-confirm-alert' }))) {
      dispatch({ type: 'SET_LOADING', loading: true });
      DomainsService.adminDelete(id)
        .then(({ status }) => {
          if (status === 'Accepted') toast.success(<FormattedMessage id="administration-success-data" />);
          else toast.error(<FormattedMessage id="global-error-try-later" />);
          getData();
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  return (
    <CustomCard icon={<IconData />} title="Dominios">
      <div className="table-content">
        <Table responsive="sm" striped bordered hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Afiliado</th>
              <th>Dominio</th>
              <th>Activo</th>
              <th>Editar</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.ID}</td>
                  <td>{`${item.ID_AFFILIATE} | ${item.affiliate.contacto} | ${item.affiliate.empresa}`}</td>
                  <td>
                    <a href={`https://${item.DOMAIN}`} target="_blank" rel="noreferrer">
                      {item.DOMAIN}
                    </a>
                  </td>
                  <td>{Number(item.ACTIVE) === 1 && <FontAwesomeIcon icon={faCheck} />}</td>
                  <td>
                    <a href={`/admintools/domains/${item.ID}`}>
                      <Button className="mr-0">
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </a>
                  </td>

                  <td className="text-center">
                    <Button red onClick={() => remove(item.ID)}>
                      <FontAwesomeIcon icon={faEraser} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </CustomCard>
  );
};

export default AdminDomains;
