import { createContext, useContext } from 'react';

import { ErrorMessage } from 'components/Form/ErrorMessage';

const FormFieldContext = createContext({});

export const useFormField = () => useContext(FormFieldContext);

export const FormField = ({ error = false, errorMessage = null, variant = 'light', children }) => {
  return (
    <FormFieldContext.Provider value={{ error, errorMessage, variant }}>
      <div className="flex flex-col gap-1">
        {children}
        {errorMessage && (
          <ErrorMessage
            variant={variant}
            style={{
              marginTop: '0.5rem',
            }}>
            {errorMessage}
          </ErrorMessage>
        )}
      </div>
    </FormFieldContext.Provider>
  );
};
