import { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Card, Col, Row } from 'react-bootstrap';
import { Tabs, Tab } from 'components/Tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import EasyMDE from 'easymde';

import 'easymde/dist/easymde.min.css';

import WhiteLabelsService from 'shared/white-labels-service';
import { WHITELABELS } from 'shared/constants';
import { buildPathnames } from 'shared/whitelabelsUtils';
import { useAuth } from 'contexts/auth';

import { getAuth, getDevBugCookie } from 'libs/cookies';

import Button from 'components/button';
import FilterItems from 'components/whiteLabels/FilterItems';

import SupportIcon from 'components/icons/sopport';

const Seo = ({ sitemode, id = null, admin = false }) => {
  const auth = getAuth();
  const { formatMessage } = useIntl();
  const seoLangs = ['es', 'en', 'de', 'it', 'fr', 'pt'];

  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState('home');
  const [filter, setFilter] = useState('');
  const [pathnames, setPathnames] = useState({});
  const [data, setData] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await setData(null);
      const _pathnames = buildPathnames({
        section,
        filter,
        langs: seoLangs,
      });
      setPathnames(_pathnames);

      let message;

      if (admin) {
        const response = await WhiteLabelsService.adminGetSeo({
          id,
          pathnames: Object.values(_pathnames),
        });
        message = response.message;
      } else {
        const response = await WhiteLabelsService.getSeo({
          id,
          pathnames: Object.values(_pathnames),
        });
        message = response.message;
      }

      setData(message);
      setHasUnsavedChanges(false); // Reset changes after data is loaded
      setLoading(false);
    })();
  }, [section, filter]);

  const submit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let status;

    if (admin) {
      const response = await WhiteLabelsService.adminUpdateSeo({
        id_mb: id,
        data,
      });
      status = response.status;
    } else {
      const response = await WhiteLabelsService.updateSeo({
        id_mb: id,
        data,
      });
      status = response.status;
    }

    setLoading(false);
    if (status === 'Accepted') {
      toast.success(<FormattedMessage id="global-success-operation" />);
      setHasUnsavedChanges(false);
    } else toast.error(<FormattedMessage id="global-error-try-later" />);
  };

  const onChange = (e, pathname) => {
    const { name, value } = e.target;

    setData((prevState) => {
      const updatedState = { ...prevState };
      const prevDataForPath = updatedState[pathname] || {
        title: '',
        description: '',
        h1: '',
        h2: '',
        seo_text: '',
      };

      // Crear una copia para actualizarla
      const newDataForPath = { ...prevDataForPath, [name]: value };

      // Comparar si hay cambios entre el anterior y el nuevo estado
      const hasChanges = Object.keys(newDataForPath).some((key) => newDataForPath[key] !== prevDataForPath[key]);

      if (hasChanges) {
        setHasUnsavedChanges(true); // Solo marcar cambios si realmente existen
      }

      // Actualizar el estado
      updatedState[pathname] = newDataForPath;
      return updatedState;
    });
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    const currentSection = section;
    const currentFilter = filter;

    if (hasUnsavedChanges) {
      const confirmDiscard = window.confirm(formatMessage({ id: 'global-unsaved-changes-sure-continue' }));

      if (!confirmDiscard) {
        e.target.value = name === 'section' ? currentSection : currentFilter;
        return; // Stop the change
      }
    }

    if (name === 'section') {
      setSection(value);
      setFilter('');
    } else if (name === 'filter') {
      setFilter(value);
    }
  };

  const clearCache = async () => {
    setLoading(true);

    try {
      const devCookie = getDevBugCookie();
      let baseHref = process.env.REACT_APP_API_URL;

      if (typeof devCookie !== 'undefined') {
        if (devCookie !== undefined && devCookie !== null && devCookie !== 'default') {
          baseHref = devCookie;
        }
      }

      const isStagingApi = baseHref.startsWith('https://devapi');
      const baseUrl = isStagingApi ? 'https://staging.olecams.com' : 'https://www.olecams.com';

      const url = `${baseUrl}/api/revalidate?tag=seo`;

      console.log('Clearing cache at:', url);

      const response = await fetch(url, { method: 'GET' });

      if (!response.ok) {
        console.log(`Error en la respuesta: ${response.status}`);
      }

      //const result = await response.json();
      toast.success(<FormattedMessage id="system-seo-cache-cleaned" />);
    } catch (error) {
      toast.warn(`Error clearing cache: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className="mb-3">
      <Col>
        <Card className="mb-2">
          <Card.Header>
            <FormattedMessage id="global-edit-seo-settings" />
          </Card.Header>
          <Card.Body>
            <div>
              <Row className="mb-2">
                <Col sm="12" className="text-center">
                  <NavLink target="_blank" to="/soporte" role="button" className="underlined">
                    <FormattedMessage id="herramientas-white-label-problem-creating-support" /> <SupportIcon color="#fc531d" />
                  </NavLink>
                </Col>
              </Row>
              <hr />
              <div
                className="d-flex"
                style={{
                  placeItems: 'end',
                  gap: '.5rem',
                }}>
                <label style={{ marginBottom: 0 }}>
                  <FormattedMessage id="global-section" />
                  <select className="form-control" name="section" defaultChecked={section} onChange={handleSelectChange} disabled={loading}>
                    <option value="home">{formatMessage({ id: 'global-home' })}</option>
                    <option value="girls">{formatMessage({ id: 'global-girls' })}</option>
                    <option value="trans">Trans</option>
                    <option value="couples">
                      {formatMessage({
                        id: 'global-couples',
                      })}
                    </option>
                    <option value="room">{formatMessage({ id: 'global-room' })}</option>
                    <option value="webcam">Webcam</option>
                    <option value="profile">{formatMessage({ id: 'global-profile' })}</option>
                  </select>
                </label>
                {sitemode === WHITELABELS.MODES.REGISTERED && (
                  <>
                    {section === 'girls' && (
                      <FilterItems segmentId={1} filter={filter} handleSelectChange={handleSelectChange} disabled={loading} />
                    )}
                    {section === 'trans' && (
                      <FilterItems segmentId={2} filter={filter} handleSelectChange={handleSelectChange} disabled={loading} />
                    )}
                    {section === 'couples' && (
                      <FilterItems segmentId={4} filter={filter} handleSelectChange={handleSelectChange} disabled={loading} />
                    )}
                  </>
                )}

                <Button disabled={loading} onClick={submit}>
                  <FormattedMessage id="administration-data-button" />
                </Button>
              </div>

              {auth.admin === 1 && (
                <div className="mb-2 mt-2">
                  <Button onClick={clearCache} disabled={loading}>
                    <FormattedMessage id="global-clean-seo-cache" />
                  </Button>
                </div>
              )}

              <div
                style={{
                  height: '1rem',
                }}
              />

              <Tabs>
                <Tab title={<FormattedMessage id="global-spanish" />}>
                  <TabContent
                    tabContentvalue="es"
                    section={section}
                    filter={filter}
                    pathname={pathnames['es']}
                    data={data?.[pathnames['es']]}
                    onChange={onChange}
                    loading={loading}
                  />
                </Tab>
                <Tab title={<FormattedMessage id="global-english" />}>
                  <TabContent
                    tabContentvalue="en"
                    section={section}
                    filter={filter}
                    pathname={pathnames['en']}
                    data={data?.[pathnames['en']]}
                    onChange={onChange}
                    loading={loading}
                  />
                </Tab>
                <Tab title={<FormattedMessage id="global-german" />}>
                  <TabContent
                    tabContentvalue="de"
                    section={section}
                    filter={filter}
                    pathname={pathnames['de']}
                    data={data?.[pathnames['de']]}
                    onChange={onChange}
                    loading={loading}
                  />
                </Tab>
                <Tab title={<FormattedMessage id="global-italian" />}>
                  <TabContent
                    tabContentvalue="it"
                    section={section}
                    filter={filter}
                    pathname={pathnames['it']}
                    data={data?.[pathnames['it']]}
                    onChange={onChange}
                    loading={loading}
                  />
                </Tab>
                <Tab title={<FormattedMessage id="global-french" />}>
                  <TabContent
                    tabContentvalue="fr"
                    section={section}
                    filter={filter}
                    pathname={pathnames['fr']}
                    data={data?.[pathnames['fr']]}
                    onChange={onChange}
                    loading={loading}
                  />
                </Tab>
                <Tab title={<FormattedMessage id="global-portuguese" />}>
                  <TabContent
                    tabContentvalue="pt"
                    section={section}
                    filter={filter}
                    pathname={pathnames['pt']}
                    data={data?.[pathnames['pt']]}
                    onChange={onChange}
                    loading={loading}
                  />
                </Tab>
              </Tabs>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

const TabContent = ({ section, pathname, data, onChange = {}, loading }) => {
  const sectionsKeywords = {
    home: ['{site-title}'],
    girls: ['{site-title}'],
    trans: ['{site-title}'],
    couples: ['{site-title}'],
    room: ['{site-title}', '{nick}'],
    profile: ['{site-title}', '{nick}'],
    webcam: ['{site-title}', '{nick}'],
  };

  return (
    <>
      <div
        className="d-flex"
        style={{
          paddingTop: '1rem',
          paddingBottom: '1rem',
          gap: '2rem',
          textAlign: 'center',
          justifyContent: 'center',
          fontWeight: '600',
        }}>
        <div>
          Pathname
          <div
            style={{
              paddingTop: '0.25rem',
              paddingBottom: '0.25rem',
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem',
              borderWidth: '1px',
              borderRadius: '0.25rem',
              borderColor: 'hsl(214.3 31.8% 91.4% / 1)',
              borderStyle: 'solid',
              backgroundColor: 'rgb(245 245 245 / 1)',
            }}>
            {pathname}
          </div>
        </div>
        <div>
          <FormattedMessage id="herramientas-white-label-metatag-keywords" />
          <div
            style={{
              paddingTop: '0.25rem',
              paddingBottom: '0.25rem',
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem',
              borderWidth: '1px',
              borderRadius: '0.25rem',
              borderColor: 'hsl(214.3 31.8% 91.4% / 1)',
              borderStyle: 'solid',
              backgroundColor: 'rgb(245 245 245 / 1)',
            }}>
            {sectionsKeywords[section].map((keyword, index) => (
              <div key={index}>{keyword}</div>
            ))}
          </div>
        </div>
      </div>
      <form className="text-left text-sm" style={{}}>
        <div
          className="grid grid-cols-2 gap-4"
          style={{
            gap: '1rem',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            display: 'grid',
          }}>
          <Form.Group>
            <Form.Label className="font-weight-bold w-100">
              <FormattedMessage id="global-title" />
              <Form.Control
                as="textarea"
                name="title"
                value={data?.title || ''}
                onChange={(e) => onChange(e, pathname)}
                disabled={loading}
                placeholder=""
                style={{ height: '60px' }}
              />
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label className="font-weight-bold w-100">
              <FormattedMessage id="global-description" />
              <Form.Control
                as="textarea"
                name="description"
                value={data?.description || ''}
                onChange={(e) => onChange(e, pathname)}
                disabled={loading}
                placeholder=""
                style={{ height: '60px' }}
              />
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label className="font-weight-bold w-100">
              H1
              <Form.Control
                as="textarea"
                name="h1"
                value={data?.h1 || ''}
                onChange={(e) => onChange(e, pathname)}
                disabled={loading}
                placeholder=""
                style={{ height: '60px' }}
              />
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label className="font-weight-bold w-100">
              H2
              <Form.Control
                as="textarea"
                name="h2"
                value={data?.h2 || ''}
                onChange={(e) => onChange(e, pathname)}
                disabled={loading}
                placeholder=""
                style={{ height: '60px' }}
              />
            </Form.Label>
          </Form.Group>
        </div>
        <Form.Group>
          <Form.Label className="font-weight-bold w-100">
            <FormattedMessage id="global-seo-text" />
          </Form.Label>
          {pathname && <MarkdownEditor name="seo_text" value={data?.seo_text || ''} onChange={onChange} pathname={pathname} />}
        </Form.Group>
      </form>
    </>
  );
};

const MarkdownEditor = ({ name, value, onChange, pathname, ...props }) => {
  const editorRef = useRef(null);
  const easyMDERef = useRef(null);
  const skipChangeEvent = useRef(false);
  const { admin } = useAuth();

  useEffect(() => {
    if (!pathname) return; // No inicializar si pathname no tiene valor

    if (easyMDERef.current) {
      // Si el editor ya está inicializado, destrúyelo antes de crear uno nuevo
      easyMDERef.current.toTextArea();
      easyMDERef.current = null;
    }

    // Crear una nueva instancia de EasyMDE
    easyMDERef.current = new EasyMDE({
      element: editorRef.current,
      initialValue: value || '',
      spellChecker: false,
      sideBySideFullscreen: false,
      maxHeight: '400px',
      toolbar: [
        'bold',
        'italic',
        'heading-1',
        'heading-2',
        'heading-3',
        'unordered-list',
        'ordered-list',
        '|',
        'link',
        'preview',
        'side-by-side',
      ],
    });

    // Active "side-by-side"
    if (easyMDERef.current) easyMDERef.current.toggleSideBySide();

    // Manejar el evento `change`
    easyMDERef.current.codemirror.on('change', () => {
      if (!skipChangeEvent.current && onChange) {
        let editorValue = easyMDERef.current.value();

        // Filtrar imágenes (Markdown: ![alt](url) y HTML: <img>)
        editorValue = editorValue.replace(/!\[.*?\]\(.*?\)/g, ''); // Eliminar imágenes en Markdown
        editorValue = editorValue.replace(/<img.*?>/g, ''); // Eliminar imágenes en HTML

        // Validar enlaces para usuarios no administradores
        if (!admin) {
          // 🔹 Transformar enlaces: eliminar la URL y dejar solo el texto del hipervínculo
          editorValue = editorValue.replace(/\[(.*?)\]\(https?:\/\/[^\s]*\)/g, '$1'); // Markdown
          editorValue = editorValue.replace(/<a\s+href="https?:\/\/[^"]*">(.*?)<\/a>/g, '$1'); // HTML
        }

        const event = {
          target: {
            name,
            value: editorValue,
          },
        };
        onChange(event, pathname); // Notificar cambios al padre
      }
    });
  }, [pathname]); // Re-ejecutar cuando `pathname` cambie

  useEffect(() => {
    // Actualizar el valor del editor cuando `value` cambie externamente
    if (easyMDERef.current && value !== easyMDERef.current.value()) {
      skipChangeEvent.current = true;
      easyMDERef.current.value(value || '');
      skipChangeEvent.current = false;
    }
  }, [value]); // Re-ejecutar cuando `value` cambie

  return <textarea ref={editorRef} {...props} />;
};

export default Seo;
