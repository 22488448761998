import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { Container, Col, Row, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';

import DomainsService from 'shared/domains-service';

import { FormField } from 'components/Form/';

import useValidation from 'hooks/useValidation';

import Button from 'components/button';
import { SelectAffiliate, Active } from 'components/Domains/Form';
import { useEffect } from 'react';

const Edit = ({ id }) => {
  const { goBack } = useHistory();

  return (
    <Container className="mw-100 ha-discounts-new">
      <Row>
        <Col sm="12" className="mb-2">
          <Button rounded onClick={goBack}>
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            <FormattedMessage id="statistics-form-button-return" />
          </Button>
        </Col>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>Editar dominio</Card.Header>
            <Card.Body>
              <FormDomain id={id} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const FormDomain = ({ id }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { maxLength, minLength, notEmpty, validDomain } = useValidation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      id: id,
      domain: '',
      id_affiliate: '',
      active: '',
    },
  });

  const formValues = watch();

  useEffect(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    DomainsService.adminGet(id)
      .then(({ status, message }) => {
        if (status === 'Accepted') {
          //setValue('id', message.data.ID);
          setValue('domain', message.data.DOMAIN);
          setValue('id_affiliate', message.data.ID_AFFILIATE);
          setValue('active', message.data.ACTIVE);
          //setData(message);
        }
        if (status === 'Failed') {
          toast.error('El dominio que intentas editar no existe');
          history.push('/admintools/domains');
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [id, dispatch, history]);

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    let fieldValue = value;
    if (type === 'checkbox') fieldValue = checked ? 1 : 0;

    setValue(name, fieldValue);
  };

  const onSubmit = async () => {
    // data convert number to decimals with .
    dispatch({ type: 'SET_LOADING', loading: true });
    DomainsService.adminUpdate({ data: formValues })
      .then(({ status, message }) => {
        if (status === 'Accepted') {
          toast.success(<FormattedMessage id="global-success-operation" />);
          history.push('/admintools/domains');
        } else if (message?.validation_errors.some((error) => error.includes('domain has already been taken'))) {
          toast.error(
            <FormattedMessage
              id="validation-field-already-taken"
              values={{
                field: <FormattedMessage key="global-domain" id="global-domain" />,
              }}
            />
          );
        } else toast.error(<FormattedMessage id="global-error-try-later" />);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xl="6">
          <Form.Group as={Row} className="mb-3">
            <label className="font-weight-bold form-label col-form-label col-sm-6">
              <FormattedMessage id="global-domain" />
            </label>
            <Col sm="6">
              <FormField variant="dark" error={errors?.domain?.message} errorMessage={errors?.domain?.message}>
                <input
                  className="form-control"
                  type="text"
                  placeholder="www.domain.com"
                  {...register('domain', {
                    required: <FormattedMessage id="validation.not-empty" />,
                    validate: {
                      notEmpty,
                      validDomain,
                      minLength: (v) => minLength(v, 3),
                      maxLength: (v) => maxLength(v, 100),
                    },
                  })}
                  required
                />
              </FormField>
            </Col>
          </Form.Group>
        </Col>
        <Col xl="6">
          <FormField variant="dark" error={errors?.id_affiliate?.message} errorMessage={errors?.id_affiliate?.message}>
            <SelectAffiliate affiliateId={formValues.id_affiliate} handleChange={onChange} required />
          </FormField>
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <Active label={<FormattedMessage id="global-active" />} checked={formValues.active} onChange={onChange} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="text-center">
          <Button rounded variant="primary" type="submit">
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            <span>
              <FormattedMessage id="administration-data-button" />
            </span>
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default Edit;
