import { Col, Row, Form } from 'react-bootstrap';
import { useEffect, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AsyncSelect from 'react-select/async';

// import { CheckBox, Input, Label, FormField } from 'components/Form';
import { FormField } from 'components/Form/';

import { debounce } from 'libs/lodashAlt';

import AffiliatesService from 'shared/affiliates-service';

export const Domain = ({ label, formFieldErrors = null, ...props }) => {
  return (
    <FormGroup label={label}>
      <FormField variant="dark" error={formFieldErrors} errorMessage={formFieldErrors}>
        <input className="form-control" type="text" placeholder="www.domain.com" {...props} />
      </FormField>
    </FormGroup>
  );
};

export const SelectAffiliate = ({ affiliateId, handleChange }) => {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const debouncedPromiseOptions = useCallback(
    debounce((inputValue, callback) => {
      promiseOptions(inputValue).then((options) => callback(options));
    }, 250),
    []
  );

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      let filters = { search: inputValue };
      let results = [];
      setIsLoading(true);

      AffiliatesService.adminList({ page: 1, perPage: 20, filters })
        .then(({ status, message }) => {
          if (status === 'Accepted') {
            message.data.map((item, index) =>
              results.push({
                id: item.id,
                label: item.id + ' - ' + item.contacto + ' (' + item.empresa + ' )',
                value: item.id,
              })
            );
            resolve(results);
          } else resolve([]);
        })
        .finally(() => setIsLoading(false));
    });
  };

  const onChange = (option) => {
    setSelectedOption(option);
    handleChange({
      target: {
        name: 'id_affiliate',
        value: option ? option.value : '',
      },
    });
  };

  useEffect(() => {
    if (affiliateId > 0) {
      console.log('affiliateId');
      AffiliatesService.adminGet(affiliateId).then(({ status, message }) => {
        if (status === 'Accepted') {
          setSelectedOption({
            id: message.data.id,
            label: message.data.id + ' - ' + message.data.contacto + ' (' + message.data.empresa + ' )',
            value: message.data.id,
          });
        }
      });
    }
  }, [affiliateId]);

  const LoadingMessage = (props) => {
    return (
      <div>
        <div {...props.innerProps} style={props.getStyles('loadingMessage', props)}>
          <FormattedMessage id="global-loading" />
        </div>
      </div>
    );
  };

  return (
    <FormGroup label={<FormattedMessage id="global-affiliate" />}>
      <AsyncSelect
        styles={{
          control: (styles) => ({
            ...styles,
            borderColor: '#ced4da',
            color: '#495057',
            fontWeight: '400',
            fontSize: '1rem',
          }),
        }}
        value={selectedOption}
        defaultValue={selectedOption}
        placeholder="Selecciona ..."
        loadOptions={debouncedPromiseOptions}
        onChange={onChange}
        isClearable
        noOptionsMessage={() => formatMessage({ id: 'promo-no-results-filters' })}
        isLoading={isLoading}
        components={{ LoadingMessage }}
      />
    </FormGroup>
  );
};

export default SelectAffiliate;

export const Active = ({ label, checked, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="checkbox" name="active" checked={checked} onChange={onChange} />
    </FormGroup>
  );
};

const FormGroup = ({ label, children }) => {
  return (
    <Form.Group as={Row} className="mb-3">
      <label className="font-weight-bold form-label col-form-label col-sm-6">{label}</label>
      <Col sm="6">{children}</Col>
    </Form.Group>
  );
};
