import React from 'react';
import { useParams } from 'react-router';

//Component
import Layout from 'components/layout';
import Profile from './profile';
import Rates from './rates';
import Referrals from './referrals';
import Billing from './billing';
import Domains from './Domains';
import NewDomain from './Domains/New';
import EditDomain from './Domains/Edit';

const UserManager = () => {
  const { section, part } = useParams();

  return (
    <Layout>
      {section === 'datos' && <Profile />}
      {section === 'tarifas' && <Rates />}
      {section === 'referidos' && <Referrals />}
      {section === 'facturacion' && <Billing />}
      {section === 'domains' && (part === 'nuevo' ? <NewDomain /> : part ? <EditDomain id={part} /> : <Domains />)}
    </Layout>
  );
};

export default UserManager;
