import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export const ErrorMessage = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '0.25rem',
        alignItems: 'center',
        fontSize: '0.75rem',
        lineHeight: '1rem',
        fontWeight: 600,
        color: '#dc3545',
        marginTop: '0.5rem',
      }}>
      <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
      {children}
    </div>
  );
};
